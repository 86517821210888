
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAttendanceEach from '@/mixins/mixinAttendanceEach';
import global from "@/vuex/attendance_each/global";
import dialog from "@/vuex/dialog";
import CommonPager from "@/components/common/Pager.vue";

@Component({
  components: {
    CommonPager,
  }
})
export default class Monthchanger extends Mixins(mixinAttendanceEach) {
  get datestr() {
    return util.$t('month_day', {month: util.date2month(this.calendar_date), day: util.date2date(this.calendar_date)});
  }

  prev_date() {
    var dateobj = util.date2obj(this.calendar_date);
    dateobj.setDate(dateobj.getDate() - 1);
    this.calendar_date = util.formatDate(dateobj, 'yyyy-mm-dd');
  }
  next_date() {
    var dateobj = util.date2obj(this.calendar_date);
    dateobj.setDate(dateobj.getDate() + 1);
    this.calendar_date = util.formatDate(dateobj, 'yyyy-mm-dd');
  }

  get startdateobj() {
    return util.date2obj(this.global.payment_info.close_started_on);
  }
  get enddateobj() {
    return util.date2obj(this.global.payment_info.close_ended_on);
  }

  get is_valid_prev() {
    return this.startdateobj.getTime() < util.date2obj(this.calendar_date).getTime();
  }
  get is_valid_next() {
    return util.date2obj(this.calendar_date).getTime() < this.enddateobj.getTime();
  }
}
