
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendanceEach from "@/mixins/mixinAttendanceEach";
  import global from "@/vuex/shift/global";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import Shift from "@/typesold/shift";
  import Able from "@/typesold/able";
  import CommonText from "@/components/common/Text.vue";

  @Component({
    components: {
      CommonText,
    },
  })
  export default class Inputrow extends Mixins(mixinAttendanceEach) {
    @Prop() editshift;
    @Prop() carfare;

    get shift() {
      if (this.is_demand_page) return this.dm.shift;
      else return this.m.shift;
    }
    set shift(val) {
      if (this.is_demand_page) this.dm.setShift({ shift: val });
      else this.m.setShift({ shift: val });
    }
    get custom_carfare() {
      return this.m.carfare;
    }
    set custom_carfare(val) {
      this.m.setCarfare({ carfare: val });
    }

    get is_edit() {
      return this.m.edit_shift_flag != 0;
    }
    get is_add() {
      return this.m.add_shift_flag;
    }
  }
