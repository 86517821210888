
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAttendanceEach from '@/mixins/mixinAttendanceEach';
import dialog from "@/vuex/dialog";
import UtilPopup from "@/components/util/Popup.vue";
import CommonProgress from "@/components/common/Progress.vue";

@Component({
  components: {
    UtilPopup,
    CommonProgress,
  }
})
export default class Main extends Mixins(mixinAttendanceEach) {
  get is_display() {
    return this.m.is_open_detail_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenDetailDialog(val);
  }
}
