
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import utilMixins from "@/mixins";
  import dialog from "@/vuex/dialog";
  import CommonProgress from "@/components/common/Progress.vue";
  import mixinAttendanceEach from "@/mixins/mixinAttendanceEach";
  import AttendanceeachEmployeechanger from "@/components/attendanceeach/Employeechanger.vue";
  import AttendanceeachMonthchanger from "@/components/attendanceeach/Monthchanger.vue";
  import AttendanceeachTableMain from "@/components/attendanceeach/table/Main.vue";
  import AttendanceeachChart from "@/components/attendanceeach/Chart.vue";
  import AttendanceeachDetailMain from "@/components/attendanceeach/detail/Main.vue";

  @Component({
    components: {
      AttendanceeachEmployeechanger,
      AttendanceeachMonthchanger,
      AttendanceeachTableMain,
      AttendanceeachChart,
      AttendanceeachDetailMain,
      CommonProgress,
    },
  })
  export default class Attendance_each extends Mixins(mixinAttendanceEach) {}
