
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAttendanceEach from '@/mixins/mixinAttendanceEach';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";

@Component({
  components: {
  }
})
export default class Daterow extends Mixins(mixinAttendanceEach) {
  @Prop() carfare;
}
