
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendanceEach from "@/mixins/mixinAttendanceEach";
  import global from "@/vuex/attendance/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/attendance/manager";
  import CommonButton from "@/components/common/Button.vue";
  import CommonSelect from "@/components/common/Select.vue";

  @Component({
    components: {
      CommonButton,
      CommonSelect,
    },
  })
  export default class Daterow extends Mixins(mixinAttendanceEach) {
    @Prop() dayprop: number;
    @Prop() dateprop: string;
    @Prop() carfare;
    @Prop() is_holiday: boolean;
    @Prop() work_type: number;
    @Prop() hourly_paidhol: number;

    open_chart() {
      if (!this.is_demand_page && this.show_attendance_chart) {
        this.m.setChartDate(this.dateprop);
        this.m.setIsOpenChartDialog(true);
      }
    }

    open_chart() {
      if (!this.is_demand_page && this.show_attendance_chart) {
        this.m.setChartDate(this.dateprop);
        this.m.setIsOpenChartDialog(true);
      }
    }

    get work_type_value() {
      return this.work_type;
    }
    set work_type_value(val: number) {
      this.m.updateHoliday({
        work_type: val,
        employee_id: this.employee_id,
        date: this.dateprop,
        yearmonth: this.date,
      });
    }
    get hourly_paidhol_value() {
      return this.hourly_paidhol;
    }
    set hourly_paidhol_value(val: number) {
      this.m.updateHourlyPaidhol({
        hourly_paidhol: val,
        employee_id: this.employee_id,
        date: this.dateprop,
        yearmonth: this.date,
      });
    }

    get datestr() {
      const dateobj = util.date2obj(this.dateprop);
      if (this.is_sp) {
        return util.formatDate(dateobj, "mm/dd");
      } else {
        return util.formatDate(dateobj, "mm月dd日");
      }
    }
    get daystr() {
      return util.day2weekstr(this.dayprop);
    }
  }
