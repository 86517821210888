
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAttendanceEach from '@/mixins/mixinAttendanceEach';
import global from "@/vuex/attendance_each/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance_each/manager";
import CommonProgress from "@/components/common/Progress.vue";
import AttendanceeachDemandtableHead from "@/components/attendanceeach/demandtable/Head.vue";
import AttendanceeachTableHeadsp from "@/components/attendanceeach/table/Headsp.vue";
import AttendanceeachTableHead from "@/components/attendanceeach/table/Head.vue";
import AttendanceeachTableCalendarMain from "@/components/attendanceeach/table/calendar/Main.vue";
import AttendanceeachTableNormalTable from "@/components/attendanceeach/table/normal/Table.vue";

@Component({
  components: {
    CommonProgress,
    AttendanceeachDemandtableHead,
    AttendanceeachTableHeadsp,
    AttendanceeachTableHead,
    AttendanceeachTableCalendarMain,
    AttendanceeachTableNormalTable,
  }
})
export default class Table extends Mixins(mixinAttendanceEach) {
  created() {
    let mode = Number(util.getLocalStorage('attendance_each_mode'));
    if (mode === null) {
      mode = 1;//初期値はカレンダー
    }
    this.m.setMode(mode);
  }

  get mode_value() {
    return this.mode;
  }
  set mode_value(val) {
    if (val) {
      this.mode = 1;
    } else {
      this.mode = 0;
    }
  }
}
