import { Mutation, MutationAction, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import Vue from 'vue';

@Module({ dynamic: true, store, name: "realtime_attendance", namespaced: true })
class Realtime extends VuexModule {
  @Action({ rawError: true })
  public async getChart({date, employee_id}: {date: string, employee_id: number}) {
    const res = await util.post('attendance_each/read_chart', {date, employee_id});
    return res.data;
  }
}

export default getModule(Realtime);