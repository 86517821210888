
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendanceEach from "@/mixins/mixinAttendanceEach";
  import dialog from "@/vuex/dialog";
  import monthly from "@/vuex/shift/monthly";
  import manager from "@/vuex/shift/manager";

  @Component({
    components: {},
  })
  export default class Main extends Mixins(mixinAttendanceEach) {
    created() {
      console.log(this.calendar_date); //calendar_dateの初期値がセットされず、初めに開いたときに今日の１ヶ月後で必ずポップアップが開いてしまうバグを修正するためのもの
    }
    get date_array_with_holiday() {
      return util.getSpanDateArrayNationalHoliday(
        this.header.national_holidays,
        this.global.payment_info.close_started_on,
        this.global.payment_info.close_ended_on,
      );
    }

    is_same_date(date1, date2) {
      if (date1.getFullYear() == date2.getFullYear()) {
        if (date1.getMonth() == date2.getMonth()) {
          if (date1.getDate() == date2.getDate()) {
            return true;
          }
        }
      }
      return false;
    }
    is_same_month(date) {
      return (
        this.startdateobj.getTime() <= date.getTime() && date.getTime() <= this.enddateobj.getTime()
      );
    }

    get startdateobj() {
      return util.date2obj(this.global.payment_info.close_started_on);
    }
    get enddateobj() {
      return util.date2obj(this.global.payment_info.close_ended_on);
    }

    get calendar_data() {
      const startdateobj = new Date(this.startdateobj);
      const enddateobj = this.enddateobj;

      //初めの週を日曜まで戻す
      for (let i = startdateobj.getDay(); i > 0; i--) {
        startdateobj.setDate(startdateobj.getDate() - 1);
      }

      const calendar_data = [];

      for (;;) {
        const calendar_week = [];
        for (let j = 0; j < 7; j++) {
          const date = new Date(startdateobj);
          const shifts = this.shifts[util.formatDate(date, "yyyy-mm-dd")];
          var shift_punches = [];
          var is_before = false;
          if (date.getTime() < new Date().getTime()) {
            is_before = true;
          } else {
            is_before = false;
          }
          if (shifts) {
            shifts.forEach((shift) => {
              let work_hour = 0;
              shift.shift_punch.forEach((shift_punch) => {
                if (
                  is_before &&
                  shift_punch.rounded_started_at !== null &&
                  shift_punch.rounded_ended_at !== null
                ) {
                  work_hour += shift_punch.rounded_ended_at - shift_punch.rounded_started_at;
                }
              });
              shift.shift_break.forEach((shift_break) => {
                if (
                  is_before &&
                  shift_break.rounded_started_at !== null &&
                  shift_break.rounded_ended_at !== null
                ) {
                  work_hour -= shift_break.rounded_ended_at - shift_break.rounded_started_at;
                }
              });
              shift_punches.push(work_hour);
            });
            shift_punches.sort((x, y) => util.sort_idx(x, y, "asc"));
          }
          calendar_week.push({
            date,
            punches: shift_punches,
            is_error: this.attendance_error[util.formatDate(date, "yyyy-mm-dd")],
          });
          startdateobj.setDate(startdateobj.getDate() + 1);
        }
        calendar_data.push(calendar_week);
        if (startdateobj.getTime() > enddateobj.getTime()) {
          break;
        }
      }

      return calendar_data;
    }

    setDate(date: Date) {
      if (
        this.startdateobj.getTime() <= date.getTime() &&
        date.getTime() <= this.enddateobj.getTime()
      ) {
        this.calendar_date = util.formatDate(date, "yyyy-mm-dd");
        this.m.setIsOpenAttendanceEachDialog(true);
      }
    }

    get shifts() {
      return this.monthly.shifts;
    }
  }
