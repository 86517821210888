
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendanceEach from "@/mixins/mixinAttendanceEach";
  import global from "@/vuex/shift/global";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import Shift from "@/typesold/shift";
  import Able from "@/typesold/able";
  import CommonButton from "@/components/common/Button.vue";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonTime from "@/components/common/Time.vue";

  @Component({
    components: {
      CommonButton,
      CommonSelect,
      CommonTime,
    },
  })
  export default class Inputrow extends Mixins(mixinAttendanceEach) {
    @Prop() editshift;
    @Prop() shifts;
    @Prop() index: number;
    @Prop() carfare;
    @Prop() work_type: number;

    get work_time() {
      let work_hour = 0;
      this.editshift.shift_punch.forEach((shift_punch) => {
        if (shift_punch.started_at !== null && shift_punch.ended_at !== null) {
          work_hour += shift_punch.rounded_ended_at - shift_punch.rounded_started_at;
        }
      });
      this.editshift.shift_break.forEach((shift_break) => {
        if (shift_break.started_at !== null && shift_break.ended_at !== null) {
          work_hour -= shift_break.rounded_ended_at - shift_break.rounded_started_at;
        }
      });

      return work_hour;
    }

    get shift() {
      if (this.is_demand_page) return this.dm.shift;
      else return this.m.shift;
    }
    set shift(val) {
      if (this.is_demand_page) this.dm.setShift({ shift: val });
      else this.m.setShift({ shift: val });
    }
    get custom_carfare() {
      return this.m.carfare;
    }
    set custom_carfare(val) {
      this.m.setCarfare({ carfare: val });
    }

    get branch_month_hash() {
      if (this.monthly) {
        const branch_hash = {};
        Object.keys(this.monthly.branch_hash).forEach((branch_id) => {
          if (!this.shifts.find((shift) => shift.branch_id == Number(branch_id)))
            branch_hash[branch_id] = this.monthly.branch_hash[branch_id];
        });
        return branch_hash;
      }
    }

    @Watch("is_add")
    on_change_is_add(newval, oldval) {
      if (newval && !oldval) {
        this.shift = {
          id: null,
          date: null,
          branch_id: null,
          employee_id: null,
          shift_shift: [],
          shift_punch: [],
          shift_break: [],
        };
        if (!this.is_demand_page) this.custom_carfare = this.carfare.money;
        if (!this.is_demand_page) this.shift_input();
        this.punch_input();
        this.break_input();

        //初期値として店舗を入れておく
        if (Object.keys(this.branch_month_hash).length != 0) {
          if (this.branch_month_hash[this.employee.branch_id]) {
            this.shift.branch_id = this.employee.branch_id;
          } else {
            this.shift.branch_id = Object.keys(this.branch_month_hash)[0];
          }
        }
      }
    }

    @Watch("is_edit")
    on_change_is_edit(newval, oldval) {
      if (newval && !oldval) {
        this.shift = {
          id: this.editshift.id,
          date: this.calendar_date,
          branch_id: this.editshift.branch_id,
          employee_id: this.editshift.employee_id,
          shift_shift: !this.is_demand_page ? util.deep_copy(this.editshift.shift_shift) : [],
          shift_punch: util.deep_copy(this.editshift.shift_punch),
          shift_break: util.deep_copy(this.editshift.shift_break),
        };
        if (!this.is_demand_page) this.custom_carfare = this.carfare.money;
        if (!this.is_demand_page) this.shift_input();
        this.punch_input();
        this.break_input();
      }
    }

    create_shift() {
      this.m.createShift({
        employee_id: this.employee_id,
        date: this.calendar_date,
        yearmonth: this.date,
      });
    }
    update_shift() {
      this.m.updateShift({
        employee_id: this.employee_id,
        date: this.calendar_date,
        yearmonth: this.date,
        shift_id: this.m.edit_shift_flag,
      });
    }

    create_demand_punch() {
      this.dm.createShift({
        employee_id: this.employee_id,
        date: this.calendar_date,
        yearmonth: this.date,
      });
    }
    update_demand_punch() {
      this.dm.updateShift({
        employee_id: this.employee_id,
        date: this.calendar_date,
        yearmonth: this.date,
      });
    }

    get is_edit() {
      return (
        this.editshift &&
        (this.m.edit_shift_flag == this.editshift.id ||
          this.dm.edit_shift_flag == this.editshift.id)
      );
    }
    get is_add() {
      return (
        !this.editshift &&
        (this.m.add_shift_flag == this.calendar_date ||
          this.dm.add_shift_flag == this.calendar_date)
      );
    }

    shift_input() {
      this.shift.shift_shift = this.time_input(this.shift.shift_shift);
    }
    punch_input() {
      this.shift.shift_punch = this.time_input(this.shift.shift_punch);
    }
    break_input() {
      this.shift.shift_break = this.time_input(this.shift.shift_break);
    }

    time_input(shift_times) {
      const length = shift_times.length - 1;
      shift_times = shift_times.filter(
        (timeint, j) => timeint.started_at != null || timeint.ended_at != null || length == j,
      );
      if (shift_times.length == 0) {
        shift_times.push({ id: null, shift_id: null, started_at: null, ended_at: null });
      } else {
        const shift_time = shift_times[shift_times.length - 1];
        if (shift_time.started_at != null || shift_time.ended_at != null) {
          shift_times.push({ id: null, shift_id: null, started_at: null, ended_at: null });
        }
      }
      return shift_times;
    }

    get is_first_row() {
      return (
        (this.index !== undefined && this.index === 0) ||
        this.m.add_shift_flag == this.calendar_date ||
        this.dm.add_shift_flag == this.calendar_date
      );
    }

    get work_type_value() {
      return this.work_type;
    }
    set work_type_value(val: number) {
      this.m.updateHoliday({
        employee_id: this.employee_id,
        date: this.calendar_date,
        yearmonth: this.date,
        work_type: val,
      });
    }

    get datestr() {
      const dateobj = util.date2obj(this.calendar_date);
      return util.formatDate(dateobj, "mm月dd日");
    }
    get daystr() {
      return util.day2weekstr(this.dayprop);
    }

    get demand_delete() {
      if (this.demand_punch === undefined) {
        return false;
      } else {
        let is_delete = false;
        this.demand_punch.forEach((demand_punch) => {
          if (demand_punch.status == 0 && demand_punch.type == 2) {
            is_delete = demand_punch.id;
          }
        });
        return is_delete;
      }
    }
    get demand_edit() {
      if (this.demand_punch === undefined) {
        return false;
      } else {
        let is_edit = false;
        this.demand_punch.forEach((demand_punch) => {
          if (demand_punch.status == 0 && demand_punch.type == 1) {
            is_edit = demand_punch.id;
          }
        });
        return is_edit;
      }
    }

    get shift_max_row() {
      return Math.max(this.shift.shift_shift?.length ?? 1, 1);
    }
    get punch_max_row() {
      return Math.max(this.shift.shift_punch?.length ?? 1, 1);
    }
    get break_max_row() {
      return Math.max(this.shift.shift_break?.length ?? 1, 1);
    }
  }
