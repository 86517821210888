
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAttendanceEach from '@/mixins/mixinAttendanceEach';
import monthly from "@/vuex/attendance_each/monthly";
import daily from "@/vuex/attendance_each/daily";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance_each/manager";
import AttendanceeachTableCalendarAttendanceeachDatechanger from "@/components/attendanceeach/table/calendar/attendanceeach/Datechanger.vue";
import AttendanceeachTableCalendarAttendanceeachDaterow from "@/components/attendanceeach/table/calendar/attendanceeach/Daterow.vue";

@Component({
  components: {
    AttendanceeachTableCalendarAttendanceeachDatechanger,
    AttendanceeachTableCalendarAttendanceeachDaterow,
  }
})
export default class Main extends Mixins(mixinAttendanceEach) {
}
