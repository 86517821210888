
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import mixinAttendanceEach from '@/mixins/mixinAttendanceEach';
import UtilPopup from "@/components/util/Popup.vue";
import AttendanceeachTableCalendarAttendanceeachMain from "@/components/attendanceeach/table/calendar/attendanceeach/Main.vue";

@Component({
  components: {
    UtilPopup,
    AttendanceeachTableCalendarAttendanceeachMain,
  }
})
export default class Dialog extends Mixins(mixinAttendanceEach) {
  get is_display() {
    return this.m.is_open_attendance_each_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenAttendanceEachDialog(val);
  }
}
