
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendanceEach from "@/mixins/mixinAttendanceEach";
  import AttendanceeachTableNormalInputrow from "@/components/attendanceeach/table/normal/Inputrow.vue";
  import AttendanceeachTableNormalNobranchrow from "@/components/attendanceeach/table/normal/Nobranchrow.vue";
  import AttendanceeachTableNormalBranchrow from "@/components/attendanceeach/table/normal/Branchrow.vue";
  import AttendanceeachDemandtableNormalDemandpunchrow from "@/components/attendanceeach/demandtable/normal/Demandpunchrow.vue";

  @Component({
    components: {
      AttendanceeachTableNormalInputrow,
      AttendanceeachTableNormalNobranchrow,
      AttendanceeachTableNormalBranchrow,
      AttendanceeachDemandtableNormalDemandpunchrow,
    },
  })
  export default class Daterow extends Mixins(mixinAttendanceEach) {
    @Prop() dateprop: string;
    @Prop() dayprop: number;
    @Prop() is_holiday: boolean;
    @Prop() work_type: number;
    @Prop() hourly_paidhol: number;
    @Prop() carfare;
    @Prop() max_row_per_date: number;
    @Prop() shifts;

    get datestr() {
      const dateobj = util.date2obj(this.dateprop);
      return util.formatDate(dateobj, "mm月dd日");
    }

    get is_demand_add() {
      let is_demand_add = false;
      if (this.is_demand_page)
        if (this.demand_punches[this.dateprop]) {
          Object.keys(this.demand_punches[this.dateprop]).forEach((branch_id) => {
            this.demand_punches[this.dateprop][branch_id].forEach((demand_punch) => {
              if (demand_punch.type == 0) {
                is_demand_add = true;
              }
            });
          });
        }
      return is_demand_add;
    }
  }
