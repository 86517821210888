
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAttendanceEach from '@/mixins/mixinAttendanceEach';
import dialog from "@/vuex/dialog";

@Component({
  components: {
  }
})
export default class Employeechanger extends Mixins(mixinAttendanceEach) {
  get employee_name() {
    if (!this.cannot_show && this.s_auth != 0)
      return this.employee.name+"さんの";
    else
      return '';
  }
  get is_prev_employee() {
    if (!this.cannot_show)
      return this.monthly.prev_employee_id != null;
  }
  get is_next_employee() {
    if (!this.cannot_show)
      return this.monthly.next_employee_id != null;
  }
  prev_employee() {
    this.employee_id = this.monthly.prev_employee_id;
  }
  next_employee() {
   this.employee_id = this.monthly.next_employee_id;
  }

  get cannot_show() {
    return !this.global || !this.monthly;
  }
}
