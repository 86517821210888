
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAttendanceEach from '@/mixins/mixinAttendanceEach';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import AttendanceeachTableCalendarAttendanceeachSpInputrow from "@/components/attendanceeach/table/calendar/attendanceeach/sp/Inputrow.vue";
import AttendanceeachTableCalendarAttendanceeachInputrow from "@/components/attendanceeach/table/calendar/attendanceeach/Inputrow.vue";
import AttendanceeachTableCalendarAttendanceeachSpNobranchrow from "@/components/attendanceeach/table/calendar/attendanceeach/sp/Nobranchrow.vue";
import AttendanceeachTableCalendarAttendanceeachNobranchrow from "@/components/attendanceeach/table/calendar/attendanceeach/Nobranchrow.vue";
import AttendanceeachTableCalendarAttendanceeachSpBranchrow from "@/components/attendanceeach/table/calendar/attendanceeach/sp/Branchrow.vue";
import AttendanceeachTableCalendarAttendanceeachBranchrow from "@/components/attendanceeach/table/calendar/attendanceeach/Branchrow.vue";
import AttendanceeachDemandtableCalendarSpDemandpunchrow from "@/components/attendanceeach/demandtable/calendar/sp/Demandpunchrow.vue";
import AttendanceeachDemandtableCalendarDemandpunchrow from "@/components/attendanceeach/demandtable/calendar/Demandpunchrow.vue";
import AttendanceeachTableCalendarAttendanceeachSpCarfarerow from "@/components/attendanceeach/table/calendar/attendanceeach/sp/Carfarerow.vue";
import AttendanceeachTableCalendarAttendanceeachCarfarerow from "@/components/attendanceeach/table/calendar/attendanceeach/Carfarerow.vue";
import AttendanceeachTableCalendarAttendanceeachSpCarfareinputrow from "@/components/attendanceeach/table/calendar/attendanceeach/sp/Carfareinputrow.vue";
import AttendanceeachTableCalendarAttendanceeachCarfareinputrow from "@/components/attendanceeach/table/calendar/attendanceeach/Carfareinputrow.vue";

@Component({
  components: {
    AttendanceeachTableCalendarAttendanceeachSpInputrow,
    AttendanceeachTableCalendarAttendanceeachInputrow,
    AttendanceeachTableCalendarAttendanceeachSpNobranchrow,
    AttendanceeachTableCalendarAttendanceeachNobranchrow,
    AttendanceeachTableCalendarAttendanceeachSpBranchrow,
    AttendanceeachTableCalendarAttendanceeachBranchrow,
    AttendanceeachDemandtableCalendarSpDemandpunchrow,
    AttendanceeachDemandtableCalendarDemandpunchrow,
    AttendanceeachTableCalendarAttendanceeachSpCarfarerow,
    AttendanceeachTableCalendarAttendanceeachCarfarerow,
    AttendanceeachTableCalendarAttendanceeachSpCarfareinputrow,
    AttendanceeachTableCalendarAttendanceeachCarfareinputrow,
  }
})
export default class Daterow extends Mixins(mixinAttendanceEach) {

  get shifts() {
    return this.monthly.shifts[this.calendar_date] ?? [];
  }
  get carfare() {
    return this.monthly.carfare_hash[this.calendar_date] ?? {};
  }
  get work_type() {
    return this.monthly.holiday_hash[this.calendar_date];
  }

  get datestr() {
    const dateobj = util.date2obj(this.calendar_date);
    return util.formatDate(dateobj, 'mm月dd日');
  }

  get is_demand_add() {
    var is_demand_add = false;
    if (this.is_demand_page)
      if (this.demand_punches[this.calendar_date]) {
        Object.keys(this.demand_punches[this.calendar_date]).forEach(branch_id => {
          this.demand_punches[this.calendar_date][branch_id].forEach(demand_punch => {
            if (demand_punch.type == 0) {
              is_demand_add = true;
            }
          })
        });
      }
    return is_demand_add;
  }
}
