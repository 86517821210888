
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendanceEach from "@/mixins/mixinAttendanceEach";
  import dialog from "@/vuex/dialog";
  import Shift from "@/typesold/shift";
  import manager from "@/vuex/attendance_each/manager";
  import AttendanceeachTableCalendarCalendar from "@/components/attendanceeach/table/calendar/Calendar.vue";
  import AttendanceeachTableCalendarAttendanceeachDialog from "@/components/attendanceeach/table/calendar/attendanceeach/Dialog.vue";

  @Component({
    components: {
      AttendanceeachTableCalendarCalendar,
      AttendanceeachTableCalendarAttendanceeachDialog,
    },
  })
  export default class Main extends Mixins(mixinAttendanceEach) {}
