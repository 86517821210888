
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAttendanceEach from '@/mixins/mixinAttendanceEach';
import realtime from "@/vuex/attendance_each/realtime";
import UtilPopup from "@/components/util/Popup.vue";
import CommonButton from "@/components/common/Button.vue";
import { Tooltip } from "ant-design-vue";

@Component({
  components: {
    UtilPopup,
    CommonButton,
    Tooltip,
  }
})
export default class Chart extends Mixins(mixinAttendanceEach) {
  chart = null;

  is_mid: boolean = false;
  is_preover: boolean = false;
  is_lawover: boolean = false;
  is_week_lawover: boolean = false;
  is_month_lawover: boolean = false;
  is_sixty_lawover: boolean = false;
  is_reg: boolean = false;
  is_outhol: boolean = false;
  is_lawhol: boolean = false;
  is_shift: boolean = false;

  get chart_cols() {
    return Math.floor((Number(this.chart.col_num) / Number(this.chart.division_col)));
  }

  open_help() {
    window.open('https://www.arrow-payment.com/legal/'+this.help_type+'.html', '_blank');
  }
  get help_type() {
    if (this.employee.work_type == 2 || this.employee.work_type == 4) {
      if (this.is_preover || this.is_lawover) {
        return '変形労働時間制';
      }
    }
    if (this.is_mid && !this.is_outhol && !this.is_lawhol && !this.is_preover && !this.is_lawover && !this.is_week_lawover && !this.is_month_lawover && !this.is_sixty_lawover) {
      return '深夜';
    } else if (!this.is_mid && !this.is_outhol && !this.is_lawhol && (this.is_preover || this.is_lawover || this.is_week_lawover || this.is_month_lawover || this.is_sixty_lawover)) {
      return '残業';
    } else if (this.is_mid && (this.is_outhol || this.is_lawhol) && !this.is_preover && !this.is_lawover && !this.is_week_lawover && !this.is_month_lawover && !this.is_sixty_lawover) {
      return '深夜休日';
    } else if (this.is_mid && !this.is_outhol && !this.is_lawhol && (this.is_preover || this.is_lawover || this.is_week_lawover || this.is_month_lawover || this.is_sixty_lawover)) {
      return '深夜残業';
    } else if (!this.is_mid && (this.is_outhol || this.is_lawhol) && (this.is_preover || this.is_lawover || this.is_week_lawover || this.is_month_lawover || this.is_sixty_lawover)) {
      return '休日残業';
    } else if (this.is_mid && !this.is_outhol && this.is_lawhol && (this.is_preover || this.is_lawover || this.is_week_lawover || this.is_month_lawover || this.is_sixty_lawover)) {
      return '深夜法定休日残業';
    } else if (this.is_mid && this.is_outhol && !this.is_lawhol && (this.is_preover || this.is_lawover || this.is_week_lawover || this.is_month_lawover || this.is_sixty_lawover)) {
      return '深夜法定外休日残業';
    } else {
      return false;
    }
  }

  get is_display() {
    if (this.m.is_open_chart_dialog)
      this.get_chart();
    return this.m.is_open_chart_dialog;
  }
  set is_display(val: boolean) {
    if (!val)
      this.chart = null;
    this.m.setIsOpenChartDialog(val);
  }

  async get_chart() {
    this.chart = await realtime.getChart({date: this.m.chart_date, employee_id: this.employee_id});
    console.log(this.chart);

    this.is_mid = false;
    this.is_preover = false;
    this.is_lawover = false;
    this.is_week_lawover = false;
    this.is_month_lawover = false;
    this.is_sixty_lawover = false;
    this.is_reg = false;
    this.is_outhol = false;
    this.is_lawhol = false;
    this.is_shift = false;

    this.chart.mid_chart.forEach(time => {
      if (time.is_valid) {
        this.is_mid = true;
      }
    });
    this.is_sixty_lawover = this.chart.sixty_over_chart.length !== 0;
    this.chart.over_chart.forEach(time => {
      if (time.is_valid) {
        if (time.type == 4) {
          this.is_preover = true;
        } else if (time.type == 5 || time.type == 6) {
          if (time.over_type == 3) {
            this.is_sixty_lawover = true;
          } else if (time.over_type == 2) {
            this.is_month_lawover = true;
          } else if (time.over_type == 1) {
            this.is_week_lawover = true;
          } else {
            this.is_lawover = true;
          }
        }
      }
    });
    this.chart.punch_chart.forEach(time => {
      if (time.is_valid) {
        if (time.type == 1) {
          this.is_reg = true;
        } else if (time.type == 2) {
          this.is_outhol = true;
        } else if (time.type == 3) {
          this.is_lawhol == true;
        } else if (time.type == 4) {
          this.is_preover = true;
        } else if (time.type == 5 || time.type == 6) {
          if (time.over_type == 2) {
            this.is_month_lawover = true;
          } else if (time.over_type == 1) {
            this.is_week_lawover = true;
          } else {
            this.is_lawover = true;
          }
        }
      }
    });
    this.chart.hol_chart.forEach(time => {
      if (time.is_valid) {
        if (time.type == 2) {
          this.is_outhol = true;
        } else if (time.type == 3) {
          this.is_lawhol == true;
        }
      }
    });
    this.chart.shift_chart.forEach(time => {
      if (time.is_valid) {
        this.is_shift = true;
      }
    });
  }

  get datestr() {
    return util.formatDate(util.date2obj(this.m.chart_date), 'mm月dd日');
  }
}
