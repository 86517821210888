
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendanceEach from "@/mixins/mixinAttendanceEach";
  import global from "@/vuex/attendance_each/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/attendance_each/manager";
  import validate from "@/validations/validate";
  import CommonInfo from "@/components/common/Info.vue";
  import CommonText from "@/components/common/Text.vue";

  @Component({
    components: {
      CommonInfo,
      CommonText,
    },
  })
  export default class Table extends Mixins(mixinAttendanceEach) {
    get counter() {
      return this.each_attendance.counter;
    }
    change_counter(e) {
      let val = e.target.value;
      val = val == "" ? null : val;
      if (val === null || !isNaN(val)) {
        this.m.updateCounter({ employee_id: this.employee_id, yearmonth: this.date, value: val });
      } else {
        validate(["半角数字の整数を入力してください"]);
        e.target.value = this.counter;
      }
    }

    get holidays() {
      let lawhol_day = 0;
      let outhol_day = 0;
      Object.keys(this.monthly.holiday_hash).forEach((date) => {
        if (this.monthly.holiday_hash[date] == 1) {
          outhol_day++;
        } else if (this.monthly.holiday_hash[date] == 2) {
          lawhol_day++;
        }
      });
      return { lawhol_day, outhol_day };
    }
    get sum_time() {
      let sum =
        Number(this.each_attendance.inbase_time) + Number(this.each_attendance.outbase_time);
      if (this.global.add_paidhol_time) {
        sum += Number(this.each_attendance.paidhol_time);
        sum += Number(this.each_attendance.specialhol_time);
      }
      return sum;
    }
  }
