
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendanceEach from "@/mixins/mixinAttendanceEach";
  import global from "@/vuex/shift/global";
  import monthly from "@/vuex/shift/monthly";
  import daily from "@/vuex/shift/daily";
  import dialog from "@/vuex/dialog";
  import Shift from "@/typesold/shift";
  import Able from "@/typesold/able";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonTime from "@/components/common/Time.vue";
  import CommonText from "@/components/common/Text.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonSelect,
      CommonTime,
      CommonText,
      CommonButton,
    },
  })
  export default class Inputrow extends Mixins(mixinAttendanceEach) {
    @Prop() editshift;

    @Prop() shifts;
    @Prop() index: number;
    @Prop() dayprop: number;
    @Prop() dateprop: string;
    @Prop() carfare;
    @Prop() is_holiday: boolean;
    @Prop() work_type: number;
    @Prop() hourly_paidhol: number;
    @Prop() max_row_per_date: number;

    get shift() {
      if (this.is_demand_page) return this.dm.shift;
      else return this.m.shift;
    }
    set shift(val) {
      if (this.is_demand_page) this.dm.setShift({ shift: val });
      else this.m.setShift({ shift: val });
    }
    get custom_carfare() {
      return this.m.carfare;
    }
    set custom_carfare(val) {
      this.m.setCarfare({ carfare: val });
    }

    get branch_month_hash() {
      if (this.monthly) {
        const branch_hash = {};
        Object.keys(this.monthly.branch_hash).forEach((branch_id) => {
          if (!this.shifts.find((shift) => shift.branch_id == Number(branch_id)))
            branch_hash[branch_id] = this.monthly.branch_hash[branch_id];
        });
        return branch_hash;
      }
    }

    get is_shift_punch_sync() {
      return this.monthly.is_shift_punch_sync;
    }

    @Watch("is_add")
    on_change_is_add(newval, oldval) {
      if (newval && !oldval) {
        this.shift = {
          id: null,
          date: null,
          branch_id: null,
          employee_id: null,
          shift_shift: [],
          shift_punch: [],
          shift_break: [],
        };
        if (!this.is_demand_page) this.custom_carfare = this.carfare.money;
        if (!this.is_demand_page) this.shift_input();
        this.punch_input();
        this.break_input();

        //初期値として店舗を入れておく
        if (Object.keys(this.branch_month_hash).length != 0) {
          if (this.branch_month_hash[this.employee.branch_id]) {
            this.shift.branch_id = this.employee.branch_id;
          } else {
            this.shift.branch_id = Object.keys(this.branch_month_hash)[0];
          }
        }
      }
    }

    @Watch("is_edit")
    on_change_is_edit(newval, oldval) {
      if (newval && !oldval) {
        this.shift = {
          id: this.editshift.id,
          date: this.dateprop,
          branch_id: this.editshift.branch_id,
          employee_id: this.editshift.employee_id,
          shift_shift: !this.is_demand_page ? util.deep_copy(this.editshift.shift_shift) : [],
          shift_punch: util.deep_copy(this.editshift.shift_punch),
          shift_break: util.deep_copy(this.editshift.shift_break),
        };
        if (!this.is_demand_page) this.custom_carfare = this.carfare.money;
        if (!this.is_demand_page) this.shift_input();
        this.punch_input();
        this.break_input();
      }
    }

    create_shift() {
      this.m.createShift({
        employee_id: this.employee_id,
        date: this.dateprop,
        yearmonth: this.date,
        is_shift_punch_sync: this.monthly.is_shift_punch_sync,
      });
    }
    update_shift() {
      this.m.updateShift({
        employee_id: this.employee_id,
        date: this.dateprop,
        yearmonth: this.date,
        shift_id: this.m.edit_shift_flag,
        is_shift_punch_sync: this.monthly.is_shift_punch_sync,
      });
    }

    create_demand_punch() {
      this.dm.createShift({
        employee_id: this.employee_id,
        date: this.dateprop,
        yearmonth: this.date,
      });
    }
    update_demand_punch() {
      this.dm.updateShift({
        employee_id: this.employee_id,
        date: this.dateprop,
        yearmonth: this.date,
      });
    }

    get is_edit() {
      return (
        this.editshift &&
        (this.m.edit_shift_flag == this.editshift.id ||
          this.dm.edit_shift_flag == this.editshift.id)
      );
    }
    get is_add() {
      return (
        !this.editshift &&
        (this.m.add_shift_flag == this.dateprop || this.dm.add_shift_flag == this.dateprop)
      );
    }

    shift_input() {
      this.shift.shift_shift = this.time_input(this.shift.shift_shift);
    }
    punch_input() {
      this.shift.shift_punch = this.time_input(this.shift.shift_punch);
    }
    break_input() {
      this.shift.shift_break = this.time_input(this.shift.shift_break);
    }

    time_input(shift_times) {
      const length = shift_times.length - 1;
      shift_times = shift_times.filter(
        (timeint, j) => timeint.started_at != null || timeint.ended_at != null || length == j,
      );
      if (shift_times.length == 0) {
        shift_times.push({ id: null, shift_id: null, started_at: null, ended_at: null });
      } else {
        const shift_time = shift_times[shift_times.length - 1];
        if (shift_time.started_at != null || shift_time.ended_at != null) {
          shift_times.push({ id: null, shift_id: null, started_at: null, ended_at: null });
        }
      }
      return shift_times;
    }

    get is_first_row() {
      return (
        (this.index !== undefined && this.index === 0) ||
        this.m.add_shift_flag == this.dateprop ||
        this.dm.add_shift_flag == this.dateprop
      );
    }

    get work_type_value() {
      return this.work_type;
    }
    set work_type_value(val: number) {
      this.m.updateHoliday({
        employee_id: this.employee_id,
        date: this.dateprop,
        yearmonth: this.date,
        work_type: val,
      });
    }
    get hourly_paidhol_value() {
      return this.hourly_paidhol;
    }
    set hourly_paidhol_value(val: number) {
      this.m.updateHourlyPaidhol({
        hourly_paidhol: val,
        employee_id: this.employee_id,
        date: this.dateprop,
        yearmonth: this.date,
      });
    }

    get datestr() {
      const dateobj = util.date2obj(this.dateprop);
      if (this.is_sp) {
        return util.formatDate(dateobj, "mm/dd");
      } else {
        return util.formatDate(dateobj, "mm月dd日");
      }
    }
    get daystr() {
      return util.day2weekstr(this.dayprop);
    }

    get max_row_per_branch() {
      return Math.max(
        this.shift.shift_shift.length,
        this.shift.shift_punch.length,
        this.shift.shift_break.length,
      );
    }
  }
