
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import info from "@/library/info";
import { Tooltip } from "ant-design-vue";

@Component({
  components: {
    Tooltip,
  }
})
export default class Tab extends Vue {
  @Prop() index?: string;
  infoindex = 1000;

  created() {
    if (Vue.prototype.$infoindex === undefined) {
      Vue.prototype.$infoindex = 1000;
    }
  }

  onHover() {
    Vue.prototype.$infoindex++;
    this.infoindex = Vue.prototype.$infoindex;
  }

  get info_msg() {
    if (false && info[this.index].link) {
      return '<a style="color: white!important; text-decoration: underline;" class="instruction_link" href="'+info[this.index].link+'" target="__blank">'+info[this.index].msg+'</a>';
    } else {
      return info[this.index].msg;
    }
  }
}
