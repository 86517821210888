
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAttendanceEach from '@/mixins/mixinAttendanceEach';
import dialog from "@/vuex/dialog";
import CommonPager from "@/components/common/Pager.vue";

@Component({
  components: {
    CommonPager,
  }
})
export default class Monthchanger extends Mixins(mixinAttendanceEach) {
  get monthstr() {
    if (!this.cannot_show)
      return util.$t('paid_on', {year: util.date2year(this.payment_info.paid_on), month: util.date2month(this.payment_info.paid_on), day: util.date2date(this.payment_info.paid_on)});
    else
      return util.$t('paid_on_yearmonth', {year: util.obj2year(this.dateobj), month: util.obj2month(this.dateobj)});
  }

  get cannot_show() {
    return !this.global || !this.monthly;
  }

  prev_month() {
    var dateobj = util.date2obj(this.date+"-01");
    dateobj.setMonth(dateobj.getMonth() - 1);
    this.date = util.formatDate(dateobj, 'yyyy-mm');
  }
  next_month() {
    var dateobj = util.date2obj(this.date+"-01");
    dateobj.setMonth(dateobj.getMonth() + 1);
    this.date = util.formatDate(dateobj, 'yyyy-mm');
  }
}
