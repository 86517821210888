
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinAttendanceEach from '@/mixins/mixinAttendanceEach';
import global from "@/vuex/attendance/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/attendance/manager";
import header from "@/vuex/header";
import CommonButton from "@/components/common/Button.vue";
import AttendanceeachTableNormalDaterow from "@/components/attendanceeach/table/normal/Daterow.vue";

@Component({
  components: {
    CommonButton,
    AttendanceeachTableNormalDaterow,
  }
})
export default class Table extends Mixins(mixinAttendanceEach) {
  mounted() {
    this.enableScrollHint();
  }
  
  get date_array_with_holiday() {
    return util.getSpanDateArrayNationalHoliday(this.header.national_holidays, this.global.payment_info.close_started_on, this.global.payment_info.close_ended_on);
  }

  get hide_type() {
    let col = 2;
    if (this.show_punch && this.show_punch_round) col--;
    if (this.show_break && this.show_break_round) col--;
    if (col == 0) {
      return '';
    } else if (col == 1) {
      return 'one_hide';
    } else {
      return 'all_hide';
    }
  }

  get max_row_per_date() {
    var max_row_per_date = {};
    this.date_array_with_holiday.forEach(date_with_holiday => {
      max_row_per_date[date_with_holiday.date] = 0;
      if (this.shifts[date_with_holiday.date])
        this.shifts[date_with_holiday.date].forEach(shift => {
          if (this.m.edit_shift_flag == shift.id) {
            max_row_per_date[date_with_holiday.date] += Math.max(this.m.shift.shift_shift.length, this.m.shift.shift_punch.length, this.m.shift.shift_break.length);
          } else if (this.dm.edit_shift_flag == shift.id) {
            max_row_per_date[date_with_holiday.date] += Math.max(this.dm.shift.shift_shift.length, this.dm.shift.shift_punch.length, this.dm.shift.shift_break.length);
          } else {
            max_row_per_date[date_with_holiday.date] += Math.max(shift.shift_shift.length, shift.shift_punch.length, shift.shift_break.length);
          }
        });
        if (this.m.add_shift_flag == date_with_holiday.date) {
          max_row_per_date[date_with_holiday.date] += Math.max(this.m.shift.shift_shift.length, this.m.shift.shift_punch.length, this.m.shift.shift_break.length);
        } else if (this.dm.add_shift_flag == date_with_holiday.date) {
          max_row_per_date[date_with_holiday.date] += Math.max(this.dm.shift.shift_shift.length, this.dm.shift.shift_punch.length, this.dm.shift.shift_break.length);
        }
        if (this.is_demand_page && this.demand_punches[date_with_holiday.date])
          Object.keys(this.demand_punches[date_with_holiday.date]).forEach(branch_id => {
            this.demand_punches[date_with_holiday.date][branch_id].forEach(demand_punch => {
              if (demand_punch.type != 2)
                max_row_per_date[date_with_holiday.date] += Math.max(demand_punch.demand_punch_punch.length, demand_punch.demand_punch_break.length);
            })
          });
    });
    return max_row_per_date;
  }

  get row_count() {
    var sum = 0;
    Object.keys(this.max_row_per_date).forEach(date => {
      sum += this.max_row_per_date[date];
    });
    return sum;
  }

  get shifts() {
    return this.monthly.shifts;
  }

  get holidays() {
    return this.monthly.holiday_hash;
  }
  get hourly_paidhols() {
    return this.monthly.hourly_paidhol_hash;
  }

  get carfares() {
    return this.monthly.carfare_hash;
  }
}
