
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinAttendanceEach from "@/mixins/mixinAttendanceEach";
  import global from "@/vuex/attendance/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/attendance/manager";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonButton from "@/components/common/Button.vue";
  import CommonProgress from "@/components/common/Progress.vue";

  @Component({
    components: {
      CommonSelect,
      CommonButton,
      CommonProgress,
    },
  })
  export default class Daterow extends Mixins(mixinAttendanceEach) {
    @Prop() index: number;
    @Prop() carfare;
    @Prop() shift;
    @Prop() work_type: number;
    @Prop() demand_punch;

    get demand_delete() {
      if (this.demand_punch === undefined) {
        return false;
      } else {
        let is_delete = false;
        this.demand_punch.forEach((demand_punch) => {
          if (demand_punch.status == 0 && demand_punch.type == 2) {
            is_delete = demand_punch.id;
          }
        });
        return is_delete;
      }
    }
    get demand_edit() {
      if (this.demand_punch === undefined) {
        return false;
      } else {
        let is_edit = false;
        this.demand_punch.forEach((demand_punch) => {
          if (demand_punch.status == 0 && demand_punch.type == 1) {
            is_edit = demand_punch.id;
          }
        });
        return is_edit;
      }
    }

    open_chart() {
      if (!this.is_demand_page && this.show_attendance_chart) {
        this.m.setChartDate(this.calendar_date);
        this.m.setIsOpenChartDialog(true);
      }
    }

    open_attendance_error() {
      if (this.attendance_error[this.calendar_date]) {
        this.m.setIsOpenAttendanceErrorDialog({
          val: this.attendance_error[this.calendar_date],
          holiday: this.work_type,
          config: {
            continuous_error_time: this.monthly.continuous_error_time,
            continuous_error_day: this.monthly.continuous_error_day,
          },
        });
      }
    }

    get is_first_row() {
      return (
        this.index === 0 &&
        this.m.add_shift_flag != this.calendar_date &&
        this.dm.add_shift_flag != this.calendar_date
      );
    }

    get work_type_value() {
      return this.work_type;
    }
    set work_type_value(val: number) {
      this.m.updateHoliday({
        work_type: val,
        employee_id: this.employee_id,
        date: this.calendar_date,
        yearmonth: this.date,
      });
    }

    get datestr() {
      const dateobj = util.date2obj(this.calendar_date);
      return util.formatDate(dateobj, "mm月dd日");
    }
    get daystr() {
      return util.day2weekstr(this.dayprop);
    }

    get shift_max_row() {
      return Math.max(this.shift.shift_shift?.length ?? 1, 1);
    }
    get punch_max_row() {
      return Math.max(this.shift.shift_punch?.length ?? 1, 1);
    }
    get break_max_row() {
      return Math.max(this.shift.shift_break?.length ?? 1, 1);
    }

    delete_shift(shift_id) {
      this.m.deleteShift({
        date: this.calendar_date,
        yearmonth: this.date,
        branch_id: this.shift.branch_id,
        employee_id: this.employee_id,
        shift_id,
      });
    }

    delete_demand_punch(shift_id) {
      this.dm.deleteShift({
        date: this.calendar_date,
        yearmonth: this.date,
        branch_id: this.shift.branch_id,
        employee_id: this.employee_id,
      });
    }
    cancel_demand_punch(demand_punch_id) {
      this.dm.cancelShift({
        branch_id: this.demand_punch.branch_id,
        date: this.calendar_date,
        yearmonth: this.date,
        employee_id: this.employee_id,
        demand_punch_id,
      });
    }

    admit_demand_punch(demand_punch_id) {
      this.dm.admitDemandPunch({
        yearmonth: this.date,
        employee_id: this.employee_id,
        demand_punch_id,
      });
    }
    deny_demand_punch(demand_punch_id) {
      this.dm.denyDemandPunch({
        yearmonth: this.date,
        employee_id: this.employee_id,
        demand_punch_id,
      });
    }
  }
